














import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import administrationSidebar from '@/components/administration.vue'; // @ is an alias to /src
import administrationHeader from '@/components/administrationheader.vue'; // @ is an alias to /src

@Component({
  components: {
    Header,
    administrationSidebar,
    administrationHeader
  },
})
export default class Administration extends Vue {

  public items = [{ code : '', title: '', credit : '', project : '', reference: '', description: ''}];
  account_options = [];
  branch_options = [];
  project_options = [];
  trans :any = {};

  addRow(){      
       this.items.push({ code : '', title: '', credit : '', project : '', reference: '', description: ''})
  }
  save(){
    let obj:any = {};
    obj['date'] = new Date();
    console.log(this.items);
  }
  mounted() {
    // this.getaccounts();
  }
}
